import { useState, useEffect, useContext, useRef } from 'react';
import styles from "./Day3.module.css"
import clsx from "clsx";
import useStickyState from "../../utils/stickyState";
import Button from "../common/Button";
import 'bootstrap/dist/css/bootstrap.min.css'
import { AppearingDisappearingWord, Draggables } from "./Components";
import { cn } from "../../utils/utils"
import { Droppable, Draggable, DragDropContext } from "react-beautiful-dnd";
import { useInstructionCT } from "../../utils/instruction";
import ButtonHolder from "../common/ButtonHolder";
import { getGradeLevel, useCTLesson } from '../../const/ct';
import UserContext from '../../context/UserContext';
import { ParagraphRIO } from '../ct/Paragraph-RIO';
import { useSoundManager } from '../../utils/sound';

function Day3({ itemNumber, pauseAndResume, questionIndex: _questionIndex, repeatCount, status, setStatus, countDownDetails: { start, reset, stop, countDownAmount, countDown }, submitResult, qProps: [isQuestioning, setIsQuestioning, isQuestioningHiding] }) {

    const { user: { grade } } = useContext(UserContext)
    const dayDetails = useCTLesson(itemNumber - 1, grade)
    console.log(dayDetails, itemNumber)
    const { sentenceList, passageList, paragraph: { content: paragraphContent, lexile: paragraphLexile, bolds, breaks }, questions, narrations } = dayDetails;
    const [isNextVisible, setIsNextVisible, isNextHiding] = useStickyState(true, 1000)
    const [resultStatus, setResultStatus] = useState(null)
    const [itemResults, setItemResults] = useState(null)
    const [isParagraphRead, setIsParagraphRead] = useState(false)

    const [step, setStep] = useState('start');
    // const [answerStatus, setAnswerStatus] = useState(null)
    const [selectedSentences, setSelectedSentences] = useState({});
    const soundManager = useSoundManager()

    const setInstruction = useInstructionCT()

    const clickHandler = async (item) => {
        const res = await submitResult({
            answer: item
        });


        return res;
    }
    const questionIndex = grade > 5 ? _questionIndex : _questionIndex === 0 ? 0 : _questionIndex - 1
    const questionItem = questions[questionIndex];



    useEffect(() => {
        //loop through all the questions that occurred before question index and when you find with type of "cSent", set the selectedSentences
        const selectedSentences = {}
        if (!isParagraphRead || questionItem.subType === "iWord" || questionItem.subType === "cParg") return;
        for (let i = 0; i < questionIndex; i++) {
            const item = questions[i]
            if ((item.subType == "cSent" || item.subType == "cOpts")) {
                selectedSentences[item.importantSentence] = item.color
            }
        }
        setSelectedSentences(selectedSentences)
    }, [isParagraphRead, questionIndex])
    useEffect(() => {
        start((grade <= 5 ? 2 * 60 : 5 * 60), () => {
            setIsParagraphRead(true)
        })
        soundManager.playURL(narrations["readParagraph"]).then(() => {
        });
        return () => {
            soundManager.cleanAllChannel()
        }
    }, [])


    const hideParagraph = _questionIndex === 0 && grade <= 5 && isParagraphRead
    return (
        <>
            <div className={styles.sideContainer}>
            {
                    !hideParagraph && passageList && questionItem.subType === "cParg"&&
                    <div className={clsx(styles.paragraphContainer, (sentenceList.some((k, i) => selectedSentences[i])) && styles.choosen)}>
                        <div className={styles.paragraphHeader}>
                            {
                                questionItem.subType == "cParg" ? <h2 style={{ alignSelf: "center", margin: 0, lineHeight: 0.7, color: "#3498db" }}><b>Select Evidence</b></h2> : <div />
                            }
                            <div className={styles.lexileContainer}>
                                <span className={styles.lexileTitle}>Lexile</span>
                                <span className={styles.lexileDetail} >{paragraphLexile}</span>
                            </div>
                        </div>
                        <div className={styles.innerContainer}>
                       
                            <p className="">
                                {passageList.map((item, index) => {
                                    var boldedWords = []
                                    if (typeof bolds?.[0] === "string") {
                                        boldedWords = item.split(" ").map((w, i) => bolds.includes(w) ? i : undefined).filter(d => d !== undefined)
                                    } else {
                                        boldedWords = (bolds || []).filter((k) => k[0] == index).map((k) => k[1]);
                                    }

                                    // boldedWords is the index of the word in the sentence that must be inclosed in a <b> tag
                                    return (
                                        <>
                                            <span>
                                            {index+1}.
                                            </span>
                                            <span
                                                dangerouslySetInnerHTML={{
                                                    __html:
                                                    
                                                    item.split(" ").map((word, index) => {
                                                        return (boldedWords.indexOf(index) != -1 ? `<b>${word}</b>` : word) + ""
                                                    }).join(" ")
                                                }}
                                                onClick={() => {
                                                    setResultStatus(null)
                                                    if (questionItem.subType != "cParg") return;
                                                    setSelectedSentences((result) => {
                                                        //IF THERE'S ANY OTHER INDEX ASSOCIATED WITH QUESTION ITEM.COLOR, REMOVE IT
                                                        const otherIndex = Object.keys(result).find(key => result[key] == questionItem.color)
                                                        if (otherIndex) {
                                                            const { [otherIndex]: _, ...rest } = result
                                                            return { ...rest, [index]: questionItem.color }
                                                        }
                                                       
                                                        return { ...result, [index]: questionItem.color }
                                                    })
                                                }}
                                                style={{ "--sIndex": index, paddingLeft: "5px" }}
                                                className={clsx(styles.sentenceItem,
                                                    (questionItem.subType === "cParg") && styles.activeSelection,
                                                    styles[((resultStatus == "incorrect") && selectedSentences[index]) ? "red" : selectedSentences[index]],
                                                    (((breaks && (breaks?.indexOf(index - 1) != -1)) || index === 0) && " ml-12")
                                                )}>
                                            </span>
                                            <br/><br/>
                                            

                                        </>
                                    )
                                })}
                            </p>
                        </div>

                    </div>
                }
                {
                    !hideParagraph && questionItem.subType !== "cParg" &&
                    <div className={clsx(styles.paragraphContainer, (sentenceList.some((k, i) => selectedSentences[i])) && styles.choosen)}>
                        <div className={styles.paragraphHeader}>
                            {
                                questionItem.subType == "cSent" ? <h2 style={{ alignSelf: "center", margin: 0, lineHeight: 0.7, color: "#3498db" }}><b>Select Evidence</b></h2> : <div />
                            }
                            <div className={styles.lexileContainer}>
                                <span className={styles.lexileTitle}>Lexile</span>
                                <span className={styles.lexileDetail} >{paragraphLexile}</span>
                            </div>
                        </div>
                        <div className={styles.innerContainer}>
                            <p className="">
                                {sentenceList.map((item, index) => {
                                    var boldedWords = []
                                    if (typeof bolds?.[0] === "string") {
                                        boldedWords = item.split(" ").map((w, i) => bolds.includes(w) ? i : undefined).filter(d => d !== undefined)
                                    } else {
                                        boldedWords = (bolds || []).filter((k) => k[0] == index).map((k) => k[1]);
                                    }

                                    // boldedWords is the index of the word in the sentence that must be inclosed in a <b> tag
                                    return (
                                        <>
                                            <span
                                                dangerouslySetInnerHTML={{
                                                    __html: item.split(" ").map((word, index) => {
                                                        return (boldedWords.indexOf(index) != -1 ? `<b>${word}</b>` : word) + ""
                                                    }).join(" ")
                                                }}
                                                onClick={() => {
                                                    setResultStatus(null)
                                                    if (questionItem.subType != "cSent") return;
                                                    setSelectedSentences((result) => {
                                                        //IF THERE'S ANY OTHER INDEX ASSOCIATED WITH QUESTION ITEM.COLOR, REMOVE IT
                                                        const otherIndex = Object.keys(result).find(key => result[key] == questionItem.color)
                                                        if (otherIndex) {
                                                            const { [otherIndex]: _, ...rest } = result
                                                            return { ...rest, [index]: questionItem.color }
                                                        }
                                                        return { ...result, [index]: questionItem.color }
                                                    })
                                                }}
                                                style={{ "--sIndex": index, paddingLeft: "5px" }}
                                                className={clsx(styles.sentenceItem,
                                                    questionItem.subType === "cSent" && styles.activeSelection,
                                                    styles[((resultStatus == "incorrect") && selectedSentences[index]) ? "red" : selectedSentences[index]],
                                                    (((breaks && (breaks?.indexOf(index - 1) != -1)) || index === 0) && " ml-12")
                                                )}>
                                            </span>
                                            {
                                                breaks && (breaks?.indexOf(index) != -1) && <br />
                                            }

                                        </>
                                    )
                                })}
                            </p>
                        </div>

                    </div>
                }
                {

                    (_questionIndex === 0 && isParagraphRead && grade < 6) &&
                    <ParagraphRIO
                        item={
                            questionIndex
                        }
                        itemNumber={itemNumber}
                        setStatus={setStatus}
                        status={status}
                        submitResult={submitResult}
                        start={start}
                    />
                }

                <div className={clsx(styles.questionsContainer, isParagraphRead && styles.visible, step == "next" && styles.hidden)}>

                    {
                        ((isParagraphRead && (_questionIndex > 0 || grade > 5))) &&
                        <div className={styles.innerQuestionContainer}>
                            {
                                questionItem?.type == "q1" && <Q1Item
                                    repeatCount={repeatCount}
                                    countDownDetails={{ start, reset, stop }}
                                    narrations={narrations}
                                    resultStatus={resultStatus}
                                    pauseAndResume={pauseAndResume}
                                    qProps={[isQuestioning, setIsQuestioning, isQuestioningHiding]}
                                    clearResultStatus={() => {
                                        setResultStatus(null)
                                    }}
                                    grade={grade}
                                    firstItem={
                                        questionIndex < 3
                                    } {...questionItem} selectedSentences={selectedSentences} selectSentence={(id, color) => {
                                        setSelectedSentences(prev => ({ ...prev, [id]: color }))
                                    }}
                                    submitResult={clickHandler}
                                />
                            }
                            {
                                questionItem?.type == "q2" && <Q2Item
                                    repeatCount={repeatCount}
                                    countDownDetails={{ start, reset, stop }}
                                    narrations={narrations}
                                    grade={grade}
                                    resultStatus={resultStatus}
                                    pauseAndResume={pauseAndResume}
                                    qProps={[isQuestioning, setIsQuestioning, isQuestioningHiding]}
                                    clearResultStatus={() => {
                                        setResultStatus(null)
                                    }}
                                    firstItem={
                                        questionIndex < 5
                                    } {...questionItem} submitResult={clickHandler}
                                    bolds={bolds}
                                />
                            }
                             {
                        questionItem?.type == "dragAndDrops" && <CompletingSentence
                            onComplete={async (result) => {
                                await clickHandler(result)
                            }}
                            onChange={() => {
                                setItemResults(null)
                            }}
                            countDownDetails={{ start, reset, stop }}
                            repeatCount={repeatCount}
                            questions={questionItem?.questionItems}
                            itemResults={itemResults}
                            answerCorrections={questionItem?.answerCorrections} draggableOptions={questionItem?.options} />
                    }
                        </div>
                    }
                </div>
            </div>
            {
                !isParagraphRead &&
                <ButtonHolder
                    reference={`paragraph-${countDown}`}
                    title={"Continue"} icon={"nextarrow"}
                    onClick={() => {
                        setIsParagraphRead(true)
                        stop()
                        soundManager.cleanAllChannel()
                    }}
                    isDisabled={
                        (process.env.NODE_ENV === "development" || grade <= 5) ? false :
                            countDown >= 180
                    } />
            }
        </>
    );
}


function Q1Item({ subType, narrations, countDownDetails: { start, reset, stop }, repeatCount, requiredWords, pauseAndResume, resultStatus, qProps, clearResultStatus, question, sentence, selectedSentences, firstItem, importantWords, color, importantSentence, highlight, options, correctOption, grade, selectSentence, submitResult, timer }) {
    const [selectedItems, setSelectedItems] = useState(subType !== "iWord" ? importantWords : [])
    const [clueVisible, setIsClueVisible] = useState(false)
    const setInstruction = useInstructionCT()

    //bek fix error
    const soundManager = useSoundManager()

    useEffect(() => {
        if (subType == "cOpts") {
            selectSentence(importantSentence, color)
        }

    }, [subType])
    useEffect(() => {
        setInstruction(null)
        if (firstItem) {
            //play narrations eyeItOut
            if (subType == "iWord") {
                setInstruction([narrations["eyeItOut"]])
                soundManager.playURL(narrations["eyeItOut"]).then(() => {

                });
            } else if (subType == "cSent") {
                setInstruction([narrations["detectiveRead"]])
                soundManager.playURL(narrations["detectiveRead"]).then(() => {
                });

            } else if (subType == "cOpts") {
                setInstruction([narrations["detectiveOption"]])
                soundManager.playURL(narrations["detectiveOption"]).then(() => {

                });
            }

        } else {
            if (subType == "iWord") {
                setInstruction([narrations["eyeItOut2"]])
                soundManager.playURL(narrations["eyeItOut2"]).then(() => {

                });
            } else if (subType == "cSent") {
                setInstruction([narrations["sentenceAndOption"]])
                soundManager.playURL(narrations["sentenceAndOption"]).then(() => {
                });
            }
        }

        return () => {
            setInstruction(null)
        }

    }, [firstItem, subType]);
    useEffect(() => {
        if (subType === "iWord") {
            const level = getGradeLevel(grade)
            start(level === "middle" ? 60 : 120)
        } else if (subType === "cSent") {
            const level = getGradeLevel(grade)
            start(
                level === "middle" ? 3 * 60 : 90
            )
        } else if (subType === "cOpts") {
            start(2 * 60)
        } else if (subType === "cParg") {
            const level = getGradeLevel(grade)
            start(
                level === "middle" ? 3 * 60 : 90
            )
        }
    }, [repeatCount, subType])


    const selectedSentenceId = Object.keys(selectedSentences).find(key => selectedSentences[key] === color)

    return <>
        {
            ((subType === "iWord" || subType === "cSent" || subType === "cOpts"|| subType === "cParg")) && <>
                {
                    (subType === "cSent" || subType == 'cParg') && <>
                        <span className={styles.sentenceInstruction}>{sentence}</span>
                    </>
                }
                {
                    (subType === "iWord") && <>
                        <img src="/eyeitout.gif" height={200} alt='' />
                        {
                            !clueVisible && <Button title={"?"} onClick={() => {
                                setIsClueVisible(true)
                            }} background={"green"} />
                        }
                        {
                            clueVisible && <div className={styles.topContainerQ1}>
                                <span key={selectedItems} className={styles.q1ItemList}>
                                    {selectedItems.length}/{requiredWords}
                                </span>
                            </div>
                        }

                    </>
                }

                <span className={clsx(styles.selectableQuestionItem)}>{question.split(" ").map((item, ind) => <span className={clsx(
                    styles.qItemWord,
                    styles[color],
                    (subType == "iWord") && styles.selectable,
                    (subType === "cSent" || subType === "cOpts" || subType === "cParg" ? importantWords : selectedItems).indexOf(ind) != -1 && styles.selected,
                    (subType == "iWord") && styles[resultStatus]
                )}
                    onClick={() => {
                        clearResultStatus()
                        if (subType == "iWord") {
                            if (selectedItems.indexOf(ind) != -1) {
                                setSelectedItems(prev => prev.filter(item => item != ind))
                            } else {
                                setSelectedItems(prev => [...prev, ind])
                            }
                        }
                    }}
                >{item}</span>)}</span>
            </>
        }
        {
            subType === "iWord" && <div style={{ alignSelf: "flex-end" }}>
                <ButtonHolder
                    reference={`iWord1${selectedItems?.length}`}
                    isDisabled={selectedItems?.length < 1} onClick={async () => {
                        clearResultStatus()
                        setSelectedItems([])
                        await submitResult(selectedItems)
                    }} icon={"nextarrow"} title={"Continue"} />
            </div>
        }
        {
            subType == "cSent" && <div style={{ alignSelf: "flex-end" }}>
                <ButtonHolder
                    reference={`cSent${Object.keys(selectedSentences)}`}
                    isDisabled={(Object.keys(selectedSentences)?.length < 1) || !selectedSentenceId} onClick={async () => {
                        clearResultStatus()
                        const selectedSentenceId = Object.keys(selectedSentences).find(key => selectedSentences[key] == color)
                        await submitResult(selectedSentenceId)
                    }} icon={"nextarrow"} title={"Continue"} />
            </div>
        }
         {
            subType == "cParg" && <div style={{ alignSelf: "flex-end" }}>
                <ButtonHolder
                    reference={`cParg${Object.keys(selectedSentences)}`}
                    isDisabled={(Object.keys(selectedSentences)?.length < 1) || !selectedSentenceId} onClick={async () => {
                        clearResultStatus()
                        const selectedSentenceId = Object.keys(selectedSentences).find(key => selectedSentences[key] == color)
                        await submitResult(selectedSentenceId)
                    }} icon={"nextarrow"} title={"Continue"} />
            </div>
        }
        
        {
            subType === "cOpts" && <><div className={styles.optionContainer}>
                {
                    options.map((item, i) => {
                        return <div className={clsx(styles.optionItem,
                            selectedItems.indexOf(item) !== -1 && styles[resultStatus],
                            selectedItems.indexOf(item) !== -1 && styles.selected)} onClick={() => {
                                clearResultStatus()
                                setSelectedItems([item])
                                // submitResult(i)
                            }}>{item.item}
                        </div>
                    })
                }
            </div>
                <div style={{ alignSelf: "flex-end" }}>
                    <ButtonHolder
                        reference={`cOpts1${JSON.stringify(selectedItems)}`}
                        isDisabled={selectedItems.length != 1} onClick={async () => {
                            const optionsId = options.reduce((prev, val, ind) => {
                                if (typeof prev == "number") {
                                    return prev;
                                }
                                return prev || ((selectedItems[0] === val) ? ind : null)
                            }, null)
                            await submitResult(optionsId).then(() => {
                                setIsClueVisible(false)
                                setSelectedItems([])
                            })

                        }} icon={"nextarrow"} title={"Continue"} />
                </div>
            </>
        }
    </>
}

function Q2Item({ subType, question, sentence, importantWords, countDownDetails: { start, reset, stop }, bolds, repeatCount, resultStatus, pauseAndResume, qProps, clearResultStatus, requiredWords, narrations, firstItem, options, color, subBolds, grade, importantSentence, correctOption, submitResult, timer }) {
    const [selectedItems, setSelectedItems] = useState(subType == "iReplace" ? importantWords : [])
    const [selectediReplace, setSelectediReplace] = useState(null)
    const [clueVisible, setIsClueVisible] = useState(false)
    const setInstruction = useInstructionCT()

    //bek fix error

    const soundManager = useSoundManager()
    useEffect(() => {
        setInstruction(null)
        if (firstItem) {
            //play narrations eyeItOut
            if (subType == "iWord") {
                setInstruction([narrations["eyeItOutResponse"]])
                soundManager.playURL(narrations["eyeItOutResponse"]).then(() => {

                });
            } else if (subType == "iReplace") {
                setInstruction([narrations["plugItIn"]])
                soundManager.playURL(narrations["plugItIn"]).then(() => {
                });

            }

        } else {
            if (subType == "iWord") {
                setInstruction([narrations["eyeItOutResponse"]])
                soundManager.playURL(narrations["eyeItOutResponse"]).then(() => {

                });
            } else if (subType == "iReplace") {
                setInstruction([narrations["plugItIn"]])
                soundManager.playURL(narrations["plugItIn"]).then(() => {

                });

            }
        }

    }, [firstItem, subType])

    const buttonRef = useRef(null)
    useEffect(() => {
        if (subType === "iWord") {
            const level = getGradeLevel(grade)
            start(level === "middle" ? 60 : 120)
        } else if (subType === "cSent") {
            const level = getGradeLevel(grade)
            start(
                level === "middle" ? 3 * 60 : 90
            )
        } else if (subType === "cOpts") {
            start(2 * 60)
        } else if (subType === "iReplace") {
            start(45)
        }
    }, [repeatCount, question, subType])
    return <>
        {
            subType === "iWord" && <div className={styles.q2ItemContainer}>
                <img src="/eyeitout.gif" style={{ height: 200, width: "auto", objectFit: "contain", marginBottom: 20 }} />

                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", marginBottom: 20 }}>
                    {
                        !clueVisible && <Button title={"?"} onClick={() => {
                            clearResultStatus()
                            setIsClueVisible(true)
                        }} background={"green"} />
                    }
                    {
                        clueVisible && <div className={styles.topContainerQ1}>
                            <span className={styles.q1ItemList}>
                                {selectedItems.length}/{requiredWords}
                            </span>
                        </div>
                    }
                </div>
                <span className={styles.readPgfInstruction}>Read the following sentence from the paragraph:</span>
                <span className={styles.sentenceInstruction} dangerouslySetInnerHTML={
                    {
                        __html: sentence.split(" ").map(d => (subBolds ?? bolds)?.includes(d.replace(".", "")) ? `<strong>${d}</strong>` : d).join(" ")
                    }
                }></span>
                <span className={clsx(styles.selectableQuestionItem)}>{question.split(" ").map((item, ind) => <span className={clsx(
                    styles.qItemWord,
                    styles[color],
                    styles.selectable,
                    resultStatus == "incorrect" && styles.incorrect,
                    selectedItems.indexOf(ind) !== -1 && styles.selected)}
                    onClick={() => {
                        clearResultStatus()
                        if (subType === "iWord") {
                            if (selectedItems.indexOf(ind) != -1) {
                                setSelectedItems(prev => prev.filter(item => item != ind))
                            } else {
                                setSelectedItems(prev => [...prev, ind])
                            }
                        }
                    }}
                >{item}</span>)}</span>
            </div>

        }
        {
            subType === "iReplace" && <>
                <span style={{ fontSize: 25, fontWeight: 500 }}>{sentence.split("[[item]]").map((itm, ind) => {
                    return <>
                        {itm}
                        {ind == 0 && <span className={(clsx(styles.focusWord, styles[color]))}>{(selectediReplace !== null) ? options[selectediReplace] : "------"}</span>}
                    </>
                })}</span>
                <div className={styles.optionContainer}>
                    {
                        options.map((item, i) => {
                            return <div className={clsx(styles.optionItem
                                ,
                                (selectediReplace == i) && styles[resultStatus]
                                , selectediReplace == i && styles.selected)} onClick={() => {
                                    clearResultStatus()
                                    setSelectediReplace(i)
                                }}>{item}</div>
                        })
                    }
                </div>

            </>
        }

        <div style={{ alignSelf: "flex-end" }}>
            <ButtonHolder
                reference={`${subType}${selectedItems?.length}${selectediReplace}`}
                ref={buttonRef}
                isDisabled={selectedItems?.length < 1} onClick={async () => {
                    clearResultStatus()
                    if (subType === "iWord") {
                        await submitResult(selectedItems)
                    } else if (subType === "iReplace") {
                        await submitResult(selectediReplace)
                    }

                }} icon={"nextarrow"} title={"Continue"} />
        </div>
    </>
}

function SentenceItemRow({ sentence, answerCorrections, itemResult, itemsList, rowIndex, onComplete, onClear }) {

    const handleDragEnd = (result) => {
        if (!result.destination) return; // Item was dropped outside the drop area

    }
    var mergedWord = (itemsList || []).join("")

    return <div className={cn("")}>
        <span className='bg-black p-2 inline text-sm rounded-full text-white'>
            #{rowIndex + 1}
        </span>
        <p className='!p-0 inline mx-2'>
            {
                sentence.split("[[item]]").map((itm, i) => {
                    return <>
                        <span className='text-base inline lg:text-lg  items-center gap-2'>
                            {itm}
                        </span>
                        {
                            (i == 0) && <Droppable droppableId={`row${rowIndex}Items`} >
                                {(provided, snapshot) => (
                                    <span
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}

                                        className={clsx(styles.sentenceFillableItem, (typeof itemResult != "undefined") && (itemResult ? styles.correct : styles.incorrect),)}
                                    >
                                        <AppearingDisappearingWord content={((mergedWord?.length > 0) ? (answerCorrections[mergedWord] ? answerCorrections[mergedWord] : mergedWord) : null)} />

                                        <div className={clsx(styles.draggingOverlay, snapshot.isDraggingOver && styles.visible)} />
                                        <div onClick={() => {
                                            onClear()
                                        }} className={clsx(styles.deleteOverlay, snapshot.isDraggingOver && styles.hidden)}>Clear</div>

                                    </span>
                                )}
                            </Droppable>
                        }
                    </>

                })
            }

        </p>

    </div>
}

function CompletingSentence({ draggableOptions: draggableItems, itemResults, repeatCount, countDownDetails: { start, reset, stop }, onChange, answerCorrections, questions, onComplete }) {
    const [itemValues, setItemValues] = useState([
        ...questions.map(question => {
            return [[], question.sentence]
        })
    ])

    //bek fix error useSoundManager to soundManager
    const soundManager = useSoundManager()

    useEffect(() => {
        onChange(itemValues)
    }, [itemValues])
    const handleDragEnd = (result) => {
        if (!result.destination) return; // Item was dropped outside the drop area
        //loop through all itemValues, and replace first and second value of the row where the item was dropped based on result.droppableId
        //result.droppableId is in the format row1Item1, row1Item2, row2Item1, row2Item2
        soundManager.playImmediate("drop")

        var newArray = []
        var droppableId = result.destination.droppableId;

        var draggable = draggableItems[Number.parseInt(result.draggableId)]

        for (let i = 0; i < itemValues.length; i++) {
            const row = itemValues[i];
            if (droppableId.includes(`row${i}Items`)) {
                //if the row[0] contains 3 or more items, clear everything and just add draggable, else append draggable to the end of the array
                if (row[0].length >= 3) {
                    row[0] = [draggable]
                }
                else {
                    row[0].push(draggable)
                }
            }
            newArray.push(row)
        }
        setItemValues(newArray)

        // setDroppedItem(result.draggableId);
    };

    useEffect(() => {
        // if (subType === "iWord") {
        //     const level = getGradeLevel(grade)
        //     start(level === "middle" ? 60 : 120)
        // } else if (subType === "cSent") {
        //     const level = getGradeLevel(grade)
        //     start(
        //         level === "middle" ? 3 * 60 : 90
        //     )
        // } else if (subType === "cOpts") {
        //     start(2 * 60)
        // } else if (subType === "iReplace") {
        //     start(45)
        // }
        start(3*60)
    }, [repeatCount, questions])

    return (
        <>
        <span className={styles.sentenceInstruction}>
        Read each of the sentences below.  Drag and Drop the sentences into the correct order according to the passage.
        </span>

        <span  className={clsx(styles.selectableQuestionItem,
                    styles.qItemWord,
                    styles['blue'],
                    styles.selected
                    // (subType == "iWord") && styles.selectable,
                    // (subType === "cSent" || subType === "cOpts" || subType === "cParg" ? importantWords : selectedItems).indexOf(ind) != -1 && styles.selected,
                    // (subType == "iWord") && styles[resultStatus]
                )}>
        Select SUBMIT when you have finished.
        </span>
        
        <DragDropContext onDragStart={() => {
            soundManager.playImmediate("drag")
        }} onDragEnd={handleDragEnd} >
            <div className={styles.questionPaperContainer}>
                <div className={styles.sentenceQuestionPaperInner}>
                    <div className={cn("flex flex-col gap-2")}>
                        {itemValues.map((iv, i) => {
                            return <SentenceItemRow onClear={() => {
                                setItemValues(itmVl => {
                                    return itmVl.map((it, index) => {
                                        return index == i ? [[], it[1]] : it
                                    });
                                })
                            }} itemResult={itemResults?.[i]} sentence={iv[1]} answerCorrections={answerCorrections} rowIndex={i} itemsList={iv[0]} />
                        })}

                    </div>
                </div>
                <div className={styles.actionsContainer}>
                    <Draggables droppableId={"dragArea"} items={draggableItems} />

                    <div className={styles.nextContainer}>
                        <ButtonHolder
                            reference={
                                !itemValues.every(it => it[0]?.length > 0) ? "ref-valid" : "ref-invalid"
                            }
                            isDisabled={
                                !itemValues.every(it => it[0]?.length > 0)
                            } icon={"nextarrow"} title={"Continue"} background={"green"} onClick={async () => {
                            // console.log(itemValues);
                                await onComplete(itemValues.map(it => it[0]))
                                // await onComplete(itemValues.map(it => Object.keys(it)))
                                // await onComplete(Object.keys(itemValues))
                                // await onComplete(Object.keys(draggableItems))

                            }}>Complete</ButtonHolder>
                    </div>
                </div>
            </div>


        </DragDropContext>

        </>
    );
}

export default Day3;