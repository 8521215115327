import { useContext, useRef, useState, useEffect } from "react"
import UserContext from "../context/UserContext"
import styles from '../styles/Lesson.module.css'
import LessonIntroduction from "../components/common/AssignableIntroduction"
import { useSoundManager } from "../utils/sound"
import AssignableRIO from "../components/assignables/AssingableRIO"
import { axios, uploadToS3 } from "../resources/api"
import { cn } from "../utils/utils";
import { pauseAll } from "../utils/audios";
import { playSound } from "../utils/playsound";
import ResultPage from "../components/common/ResultPage"
import { useNavigate } from "react-router-dom"

export const Assignable = () => {
    const { user } = useContext(UserContext)
    /**
   * @type {{passage: string[], grade: string, key: string}} | undefined
   */
    const data = user.assignable;
    const passageId = data?.key.replace("P", "");
    const [page, setPage] = useState("introduction");
    const soundManager = useSoundManager();
    const [status, setStatus] = useState(null);
    const [sentenceResults, setSentenceResults] = useState({});
    const s3FieldsRef = useRef(null);
    const audioBlob = useRef(null);
    const metaBlob = useRef(null);
    const wordCount = data?.passage.join(" ").split(" ").length
    const navigate = useNavigate()

    const [wasSuccessful, setWasSuccessful] = useState(false)

    const [resp, setResp] = useState(null);

    useEffect( () => {
        if (!resp) return;
        
        const handleResponse = async () => {
            if (resp.isCorrect === true) {
                setStatus("pass");
                await pauseAll()
                playSound("correct").then(r =>  window.location.reload())
                
            } else if(resp.tryAgain === true && resp.isCorrect === false) {
                setStatus("fail");
                await pauseAll()
                playSound("wrong").then(r=> setStatus("started"))
              
            }else if(resp.tryAgain === false && resp.isCorrect === false){
                setStatus("fail");
                await pauseAll()
                playSound("wrong").then(r=> window.location.reload())
               
            }else{

            }
        };
       

        handleResponse();
    }, [resp]);

    return (
        <div className={cn(styles.container, " !overflow-hidden")}>
            <head>
                <title>Checkpoint</title>
            </head>
            <div className={styles.lessonInformation}>
                <span><b>Checkpoint Passage {passageId}</b>&nbsp; &nbsp; {data?.lexile}</span>
            </div>
            {page == 'introduction' && (
                <LessonIntroduction
                    lessonId={0}
                    onNext={() => {
                        setPage("lesson")
                    }}
                    passageId={passageId}
                    grade={data?.grade}
                    wordCount={wordCount}
                    key={data?.key}
                    lexile={data?.lexile}
                    trialId={"trialId"}
                    soundManager={soundManager}
                />
            )}
            {
                page === "lesson" && data && <AssignableRIO
                    data={data}
                    current={0}
                    soundManager={soundManager}
                    status={status}
                    setStatus={setStatus}
                    questionIndex={0}
                    sentenceResults={sentenceResults}
                    setSentenceResults={setSentenceResults}
                    metaBlob={metaBlob}
                    audioBlob={audioBlob}
                    questions={data?.passage}
                    s3FieldsRef={s3FieldsRef}
                    itemDetails={{
                        type: "rio",
                        text: data?.passage.map(itm => itm.replace(/\s+/g, " "))
                    }}
                    subType={"assignable"}
                    isParagraphRIO={false}
                    clickHandler={async () => {
                        const { url, uploadId } = s3FieldsRef.current
                        let isUploaded = false;
                        if (audioBlob.current) {
                            const res = await uploadToS3(url, audioBlob.current, "audio.wav", "audio/x-wav");
                            isUploaded = true;
                        }
                        const res=(await axios.post(`/student/assignableCheckpoint/submit`, {
                            uploadId,
                            textId: data?.key,
                            grade: data?.grade,
                            meta: metaBlob.current,
                            trialId: data.trialId
                        })).data;

                        setResp(res);

                        
                    }}
                />
            }
            {
                page === "result" && <ResultPage wasSuccessful={
                    wasSuccessful
                } percentage="10%" lessonId="1" checkpoint={true} homePage={() => navigate("/")} />
            }
        </div>
    )
}