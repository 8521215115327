import styles from "./ReadItOut.module.css"
import React, { useEffect, useState } from "react";
import { playSound } from "../../utils/playsound";
import wait from "../../utils/wait";

import clsx from "clsx";
import { formatTime, genColor, randomIntFromInterval } from "../../utils/common";
import { mockAudio, pauseAll, waitForVoice } from "../../utils/audios";
import BeatRead21 from "../../components/common/BeatRead21";
import FloatingObject from "../../components/common/FloatingObjects";
import { useInstruction } from "../../utils/instruction";
import BaseStage from "../interfaces/BaseStage";
import ItemTable from "../neutral/ItemTable";
import StageIntroduction from "../introduction/StageIntroduction";
import { Flipped, Flipper } from "react-flip-toolkit";
import RecordButton from "../neutral/RecordButton";
import Button, { SlottedButton } from "../common/Button";
import useCountDown from "../../utils/countdown";
import { continueLesson, endLessonContinuate } from "../../resources/api";
import ButtonHolder from "../common/ButtonHolder";
import { cn } from "../../utils/utils";
import { getMsBg } from "../../utils/style";
import FlipNumbers from "react-flip-numbers";




class ReadItOut extends BaseStage {
    state = {
        page: ""
    }

    render() {
        const { status, data, current, nextItem, controls: { registerMicrophone, sentenceResults: results, goToResults }, stageDetails, breaks, grade } = this.props
        const { subType } = stageDetails

        const oneAfterAnother = (subType == "sequential")

        if (stageDetails.subType == "assignable") {
            return <AssignableRIO data={data} results={results} current={current} registerMicrophone={registerMicrophone} />
        }


        return <div className={styles.mainContainer}> <div className={clsx(styles.rioContainer, stageDetails.subType == "large" && styles.large, stageDetails.subType === "ct-paragraph" ? " w-full" : "")}>
            {
                stageDetails.subType == "large" ? <>
                    <div className={styles.sentences}>
                        {
                            data[current].text.map((nLine, ti) => {
                                return <div key={`line_${ti}`} className={clsx(styles.sequences, styles.active)}>{nLine.split(" ").map((word, ki) => {
                                    var previousTickers = Array.from(new Array(ti)).reduce((prev, curr, ci) => {
                                        return prev + data[current].text[ci].split(" ").length
                                    }, 0)
                                    var i = previousTickers + ki;
                                    var wRes = results?.[current]
                                    var wrdK = word.trim().replace(/[^A-Za-z0-9_ -]+/, '').toLowerCase()
                                    var result = wRes?.[wrdK + i]//virtualrep[wrdK] ? wRes?.[virtualrep[wrdK]+i] : wRes?.[wrdK+i]
                                    return <span id={`${wrdK}_${i}`} key={`${wrdK}_${i}`} className={clsx(styles.word, (result == "read") && styles.readAnimate, (typeof result == "boolean") && (result ? styles.correct : styles.error))}  >{word}</span>
                                })}</div>
                            })
                        }
                        {/* <h1></h1> */}
                    </div>
                    {/* {
                        <RecordButton
                            {...(registerMicrophone(data[current]?.microphone || stageDetails.microphone))}
                        />
                    } */}
                </>
                    : (stageDetails.subType === "ct-paragraph") ? (
                        <div className="flex items-center px-32 relative">
                            <div className={cn(styles.sentences, " w-9/12")}>
                                {
                                    Object.keys(data).filter(oKey => !oneAfterAnother || (current == oKey)).map((oKey, index) => {
                                        var wRes = results?.[oKey]
                                        return <div key={oKey} className={clsx(styles.sequences, (current == oKey) && styles.active)}> {data[oKey].text.join(" ").split(" ").map((word, wordIndex) => {
                                            var wrdK = word.trim().replace(/[^A-Za-z0-9_ -]+/, '').toLowerCase();
                                            var result = wRes?.[wrdK + wordIndex]
                                            return <span id={`${wrdK}_${wordIndex}`} key={wrdK + wordIndex} className={clsx(styles.word, (result == "read") && styles.readAnimate, (typeof result == "boolean") && (result ? styles.correct : styles.error))}>{word}</span>
                                        })} </div>
                                    })
                                }
                            </div>

                            <ButtonHolder
                                reference={JSON.stringify(
                                    { vl: (registerMicrophone(data[current]?.microphone || stageDetails.microphone)), data: data[current] }
                                )}
                                bType={"record"}
                                {...(registerMicrophone(data[current]?.microphone || stageDetails.microphone))}
                                className=" fixed top-1/2 right-1/4"
                            />

                        </div>
                    ) :
                        <>
                            <div className={cn(styles.sentences, " mx-auto")}>
                                {
                                    Object.keys(data).filter(oKey => !oneAfterAnother || (current == oKey)).map((oKey) => {
                                        var wRes = results?.[oKey]
                                        return <div key={oKey} className={clsx(styles.sequences, (current == oKey) && styles.active)}> {data[oKey].text.split(" ").map((word, i) => {
                                            var wrdK = word.trim().replace(/[^A-Za-z0-9_ -]+/, '').toLowerCase()
                                            var result = wRes?.[wrdK + i]
                                            return <span id={`${wrdK}_${i}`} key={wrdK + i} className={clsx(styles.word, (result == "read") && styles.readAnimate, (typeof result == "boolean") && (result ? styles.correct : styles.error))}  >{word}</span>
                                        })} </div>
                                    })
                                }
                                {/* <h1></h1> */}
                            </div>
                            {
                                <ButtonHolder
                                    reference={JSON.stringify(
                                        { vl: (registerMicrophone(data[current]?.microphone || stageDetails.microphone)), data: data[current] }
                                    )}
                                    bType={"record"}
                                    {...(registerMicrophone(data[current]?.microphone || stageDetails.microphone))}
                                />
                            }
                        </>
            }

        </div></div >
    }
}
const AssignableRIO = ({ data, results, current, registerMicrophone }) => {
    const { countDown, start } = useCountDown(null, true)
    const mic = registerMicrophone(true)
    useEffect(() => {
        if (mic.status === "recording" && !countDown) {
            start(60)
            mic.onClick()
           
        }
    }, [mic.status])
    // useEffect(() => {
    //     console.log('couuuuuuuuuuunt down');
    //     console.log(countDown);
    //    if(countDown<=50){

    //        mic.onClick()
    //    }
           
        
    // }, countDown)
    //replace whitespace and `""`
    data = data.map(itm => itm.replace(/\s+/g, " "))

    return (
        (
            <div className="flex max-w-3xl mx-auto  py-14">
                <div className={cn(" flex flex-col text-center overflow-y-auto")}>
                    {
                        data?.map((nLine, ti) => {
                            return <p key={`line_${ti}`} className={clsx(styles.sequences, styles.active)}>{nLine.split(" ").map((word, ki) => {
                                var previousTickers = Array.from(new Array(ti)).reduce((prev, curr, ci) => {
                                    return data[ci].split(" ").length + prev
                                }, 0)

                                var i = previousTickers + ki;
                                var wRes = results?.[current]
                                var wrdK = word.trim().replace(/[^A-Za-z0-9_ -]+/ig, '').toLowerCase()
                                var result = wRes?.[wrdK + i]//virtualrep[wrdK] ? wRes?.[virtualrep[wrdK]+i] : wRes?.[wrdK+i]
                                // console.log({ wrdK }, { i }, { result })

                                return <span id={`${wrdK}_${i}`} key={`${wrdK}_${i}`} className={clsx(styles.word, (result == "read") && styles.readAnimate, (typeof result == "boolean") && (result ? styles.correct : styles.error))}  >
                                    {word}
                                </span>
                            })}</p>
                        })
                    }
                </div>
                <div className="flex flex-col gap-4 justify-center items-center">
                    <RecordButton
                        {...mic}
                    />
                    {
                        (countDown) ? <div className={clsx(styles.timerContainer, countDown < 2 && styles.hiding
                        )} style={{
                            background: "#1E88E6"
                        }}>
                            <h1 style={{ margin: 0, padding: 0, lineHeight: 1 }}>
                                <FlipNumbers height={35} width={24} numberStyles={styles.numberStyles} color="white"
                                    play
                                    numbers={(countDown > 60) ? formatTime(countDown) : countDown.toString()} />
                            </h1>
                        </div> : <></>
                    }
                </div>
            </div>
        )
    )
}

function withMyHook(Component) {
    return function WrappedComponent(props) {
        useInstruction(props.stageDetails?.instruction && [props.stageDetails?.instruction]);
        return <Component {...props} />;
    }
}


export default withMyHook(ReadItOut);

