import { useState, useEffect, useContext, useCallback, useRef } from 'react';
import { useSoundManager } from "../../utils/sound";
import ctData from "../../const/ct.json"
import styles from "./Day4.module.css"
import clsx from "clsx";
import useStickyState from "../../utils/stickyState";
import Button from "../common/Button";
import useCountDown from "../../utils/countdown";
import { formatTime } from "../../utils/common";
import FlipNumbers from "react-flip-numbers";
import { initVoice, submitActivity, uploadToS3 } from "../../resources/api";
import { CorrectIcon, DragIcon, WrongIcon } from "../../resources/svgs";
import { Droppable, Draggable, DragDropContext } from "react-beautiful-dnd";
import ReadItOut from "../stages/ReadItOut";
import * as SpeechSDK from "microsoft-cognitiveservices-speech-sdk";
import wait from "../../utils/wait";
import { waitForVoice } from "../../utils/audios";
import DisplayItems from "../stages/DisplayItems";
import ProgressBar from 'react-bootstrap/ProgressBar';
import 'bootstrap/dist/css/bootstrap.min.css'
import { CompletingSentence } from "./Day2";
import { useInstructionCT } from "../../utils/instruction";
import CTRIO from "../ct/CTRIO";
import CTDisplay from "../ct/CTDisplay";
import ButtonHolder from "../common/ButtonHolder";
import { getGradeLevel, getTimer, useCTLesson } from '../../const/ct';
import UserContext from '../../context/UserContext';
import { cn } from "../../utils/utils"

function SentenceItemRow({ sentence, answerCorrections, itemResult, itemsList, rowIndex, onComplete, onClear }) {

    const handleDragEnd = (result) => {
        if (!result.destination) return; // Item was dropped outside the drop area

    }
    var mergedWord = (itemsList || []).join("")

    return <span className={cn("flex items-start gap-2")}>
        {
            sentence.split("[[item]]").map((itm, i) => {
                return <>
                    <span>{itm}</span>
                    {
                        (i == 0) && <Droppable droppableId={`row${rowIndex}Items`} >
                            {(provided, snapshot) => (
                                <span
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}

                                    className={clsx(styles.sentenceFillableItem, (typeof itemResult != "undefined") && (itemResult ? styles.correct : styles.incorrect))}
                                >
                                    {
                                        mergedWord?.length > 0 ? (answerCorrections[mergedWord] ? answerCorrections[mergedWord] : mergedWord) :
                                            "_______"
                                    }

                                    {
                                        snapshot.isDraggingOver ? <div className={styles.darggingOverlay} /> : <div className={styles.deleteOverlay} onClick={() => {
                                            onClear()
                                        }}>
                                            Clear
                                        </div>
                                    }

                                </span>
                            )}
                        </Droppable>
                    }
                </>

            })
        }

    </span>
}


function CompletingSentence2({ draggableOptions: draggableItems, itemResults, onChange, answerCorrections, questions, onComplete }) {
    const [itemValues, setItemValues] = useState([
        ...questions.map(question => {
            return [[], question.sentence]
        })
    ])



    useEffect(() => {
        onChange(itemValues)
    }, [itemValues])
    const handleDragEnd = (result) => {
        if (!result.destination) return; // Item was dropped outside the drop area
        //loop through all itemValues, and replace first and second value of the row where the item was dropped based on result.droppableId
        //result.droppableId is in the format row1Item1, row1Item2, row2Item1, row2Item2
        var newArray = []
        var droppableId = result.destination.droppableId;

        var draggable = draggableItems[Number.parseInt(result.draggableId)]

        for (let i = 0; i < itemValues.length; i++) {
            const row = itemValues[i];
            if (droppableId.includes(`row${i}Items`)) {
                //if the row[0] contains 3 or more items, clear everything and just add draggable, else append draggable to the end of the array
                if (row[0].length >= 3) {
                    row[0] = [draggable]
                }
                else {
                    row[0].push(draggable)
                }
            }
            newArray.push(row)
        }
        setItemValues(newArray)

        // setDroppedItem(result.draggableId);
    };


    return (
        <DragDropContext onDragEnd={handleDragEnd}>
            <div className={styles.tableContainer}>
                <div className={styles.tableInner}>
                    <div className={styles.sentencesContainer}>
                        {itemValues.map((iv, i) => {
                            return <SentenceItemRow onClear={() => {
                                setItemValues(itmVl => {
                                    return itmVl.map((it, index) => {
                                        return index == i ? [[], it[1]] : it
                                    });
                                })
                            }} itemResult={itemResults?.[i]} sentence={iv[1]} answerCorrections={answerCorrections} rowIndex={i} itemsList={iv[0]} />
                        })}

                    </div>
                    <Droppable droppableId={"dragArea"} >
                        {(provided, snapshot) => (
                            <div

                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                className={styles.draggableWordsContainer}

                            >
                                {draggableItems.map((itm, i) => ({ id: i.toString(), content: itm })).map((item, index) => (
                                    <Draggable key={item.id} draggableId={item.id} index={index}>
                                        {(provided, snapshot) => (
                                            <div
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                ref={provided.innerRef}
                                                style={{
                                                    ...provided.draggableProps.style,
                                                }}
                                                className={clsx(styles.tableTableDraggable, snapshot.isDragging && styles.dragging)}
                                            >
                                                <DragIcon fill={"#000"} height={12} />
                                                {item.content}
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                            </div>
                        )}
                    </Droppable>
                    <div className={styles.nextContainer}>
                        <Button
                            isDisabled={
                                !itemValues.every(it => it[0]?.length > 0)
                            }
                            icon={"nextarrow"}
                            title={"Continue"}
                            background={"green"}
                            onClick={() => {
                                onComplete(itemValues.map(it => it[0]))

                            }}>Complete</Button>
                    </div>

                </div>
            </div>


        </DragDropContext>
    );
}

function Day4({ itemNumber, pauseAndResume, status, setStatus, countDownDetails: { start, reset, stop }, repeatCount, questionIndex, submitResult, qProps: [isQuestioning, setIsQuestioning, isQuestioningHiding] }) {
    const { user: { grade } } = useContext(UserContext)
    const dayDetails = useCTLesson(itemNumber - 1, grade)
    const { prefix, rootWords, suffix, questions, narrations } = dayDetails;
    const [itemResults, setItemResults] = useState(null)
    const [sentenceResults, setSentenceResults] = useState({})
    const s3FieldsRef = useRef(null)
    const audioBlob = useRef(null)
    const metaBlob = useRef(null)
    const [resultStatus, setResultStatus] = useState(null)

    const [isQuestionStarted, setIsQuestionStarted] = useState(false);
    const speaksPlayRef = useRef(false)

    const typesPlayRef = useRef(false)
    const ddropsPlayRef = useRef(false)
    const rioPlayRef = useRef(false)
    const [micDisabled, setMicDisabled] = useState(true)
    const setInstruction = useInstructionCT()

    useEffect(() => {
        setStatus(null)
        setItemResults(null)
    }, [questionIndex])
    /**
     * @type {SoundManager}
     */
    const soundManager = useSoundManager();


    const itemDetails = questions[questionIndex];

    const timer = getTimer(grade)
    useEffect(() => {
        setInstruction(null)
        setMicDisabled(true)
        if (itemDetails.type == "speaks") {
            setIsQuestionStarted(false)
            if (!speaksPlayRef.current) {
                soundManager.playURL(narrations["itemWords"]).then(async () => {
                    speaksPlayRef.current = true;
                    setIsQuestionStarted(true)
                    await soundManager.playURL(itemDetails.narration).then(() => {
                        setMicDisabled(false)
                        start(15)

                    })
                })
            } else {
                setIsQuestionStarted(true)
                soundManager.playURL(itemDetails.narration).then(() => {
                    setMicDisabled(false)
                    start(15)
                })
            }
            setInstruction([narrations["itemWords"], itemDetails.narration])
        } else if ((itemDetails.type == "types")) {
            setIsQuestionStarted(false)
            //if types was ever played before, just play the sound of the typeable
            if (!typesPlayRef.current) {
                //play instruction

                soundManager.playURL(narrations["typeWord"])
                    .then(() => {
                        typesPlayRef.current = true;
                        setIsQuestionStarted(true)
                        //play the word
                        soundManager.playURL(itemDetails.narration).then(() => {
                            start(45, () => {
                                window.typesPublish()
                            });
                        })


                    })

            } else {
                setIsQuestionStarted(true)
                //play the word
                soundManager.playURL(itemDetails.narration).then(() => {

                    start(45, () => {
                        window.typesPublish()
                    })
                })
            }
            setInstruction([narrations["typeWord"], itemDetails.narration])

        } else if ((itemDetails.type == "dragAndDrops") && !ddropsPlayRef.current) {
            ddropsPlayRef.current = true

            soundManager.playURL(narrations["formSentence"]).then(() => {
                start(4 * 60)
                setIsQuestionStarted(true)
            })
            setInstruction([narrations["formSentence"]])
        } else if ((itemDetails.type == "rio") && !rioPlayRef.current) {
            rioPlayRef.current = true

            soundManager.playURL(narrations["rioInstruction"]).then(() => {
                setIsQuestionStarted(true)
                const level = getGradeLevel(grade)
                start(
                    level === "middle" ? 60 : 30
                )
                setMicDisabled(false)
            })
            setInstruction([narrations["rioInstruction"]])
        } else if (itemDetails.type == "dragAndDrops") {
            start(4 * 60)
        } else if (itemDetails.type == "rio") {
            const level = getGradeLevel(grade)
            start(
                level === "middle" ? 60 : 30
            )
            setMicDisabled(false)
        }
    }, [itemDetails])

    useEffect(() => {
        if (itemDetails.type == "speaks") {
            if (repeatCount > 0) {
                start(15)
            }

        } else if (itemDetails.type == "types") {
            if (repeatCount > 0) {
                start(45, () => {
                    window.typesPublish()
                })
            }
        } else if (itemDetails.type == "dragAndDrops") {
            if (repeatCount > 0) {
                start(4 * 60)
            }
        } else if (itemDetails.type == "rio") {
            if (repeatCount > 0) {
                const level = getGradeLevel(grade)
                start(
                    level === "middle" ? 60 : 30
                )
                // start(30)
            }
        }
    }, [repeatCount, itemDetails])






    const clickHandler = async (item) => {
        setStatus("processing")
        if (itemDetails.type == "rio") {
            setIsQuestioning(false)
            const { url, uploadId } = s3FieldsRef.current
            var isUploaded = false;
            if (audioBlob.current) {
                await uploadToS3(url, audioBlob.current, "audio.wav", "audio/x-wav");
                isUploaded = true;
            }

            const res = await submitResult({
                answer: isUploaded ? uploadId : null,
                recMeta: metaBlob.current.text
            }, false, async (result) => {
                soundManager.cleanAllChannel()
                if (result.sentence) {
                    var sentence = Object.keys(result.sentence).reduce((prev, wrd) => ({ ...prev, [wrd.toLowerCase()]: result.sentence[wrd] }), {})
                    setSentenceResults((results) => ({ ...results, [questionIndex]: sentence }))
                }
            })

            setItemResults(res)
            setStatus(null)

            /// don't know what to do for the microphone tingy
        } else if (itemDetails.type == "speaks") {
            setIsQuestioning(false)
            const { url, uploadId } = s3FieldsRef.current
            var isUploaded = false;
            if (audioBlob.current) {
                await uploadToS3(url, audioBlob.current, "audio.wav", "audio/x-wav");
                isUploaded = true;
            }

            const res = await submitResult({
                answer: isUploaded ? uploadId : null,
            }, false, async () => {
                soundManager.cleanAllChannel()

            });


            setItemResults(res)

            setStatus(null)

        } else {
            if (itemDetails.type == "types") {
                const res = await submitResult({
                    answer: item
                })
                return res;
            } else {
                const res = await submitResult({
                    answer: item
                }, true, async (res) => {
                    var itemObject = []
                    for (let i = 0; i < res.score.length; i++) {
                        const score = res.score[i];
                        itemObject.push(score)
                        setItemResults([...itemObject])
                        if (score) {
                            await soundManager.playSound("instruct", "correct", false, 0.06);
                        } else {
                            await soundManager.playSound("instruct", "wrong", false, 0.06);
                        }

                        await wait(600)
                    }
                })
            }
        }
    }


    return (
        <div className={styles.mainContainer}>
{ 
  itemDetails.direction !== undefined && 
  <div className={styles.tableContainer}>
                <div className={styles.tableInner}>
                    <div className={styles.sentencesContainer1}> 
 
    {
        itemDetails.direction.map((iv,i)=>{
            // return iv.map((itm, i) => {
            return <div className={clsx(styles.largeItem1, styles.baseItem)}>
               
                <span className={styles.word}>{iv.answer}</span>
                <span className={styles.meaning}>{iv.sentence.split("[[item]]")}</span>
                </div>
           

        // })
    })}

</div></div></div>
}
            {
                isQuestionStarted && <>
                    {
                        itemDetails.type == "speaks" && <>
                            <CTDisplay
                                status={status}
                                questionIndex={questionIndex}
                                itemDetails={itemDetails}
                                questions={questions}
                                sentenceResults={sentenceResults}
                                setSentenceResults={setSentenceResults}
                                s3FieldsRef={s3FieldsRef}
                                audioBlob={audioBlob}
                                setStatus={setStatus}
                                isQuestionDisabled={micDisabled}
                                metaBlob={metaBlob}
                                clickHandler={clickHandler}
                                soundManager={soundManager}
                            />
                        </>
                    }
                    {
                        itemDetails.type == "types" && <WordType next={async (re) => {
                            return await clickHandler(re)
                        }} {...itemDetails} />
                    }
                    {
                        itemDetails.type == "dragAndDrops" && <CompletingSentence
                            onComplete={async (result) => {
                                await clickHandler(result)
                            }}
                            onChange={() => {
                                setItemResults(null)
                            }}
                            questions={itemDetails.questionItems}
                            itemResults={itemResults}
                            answerCorrections={questions[questionIndex].answerCorrections} draggableOptions={questions[questionIndex].options} />
                    }

                    {
                        itemDetails.type == "rio" && <>
                            <CTRIO
                                status={status}
                                questionIndex={questionIndex}
                                itemDetails={itemDetails}
                                questions={questions}
                                sentenceResults={sentenceResults}
                                setSentenceResults={setSentenceResults}
                                isQuestionDisabled={micDisabled}
                                s3FieldsRef={s3FieldsRef}
                                audioBlob={audioBlob}
                                setStatus={setStatus}
                                metaBlob={metaBlob}
                                clickHandler={clickHandler}
                                soundManager={soundManager}
                            >
                            </CTRIO>

                        </>
                    }
                </>
            }




        </div>
    );
}

function WordShow({ item, next }) {
    return <>
        <span>{item}</span>
        <Button icon={"nextarrow"} title={"Continue"} background={"green"} onClick={() => {
            next()
        }}>Next</Button>
    </>
}
function WordType({ text, narration, next }) {
    const [shouldShow, setShowShow] = useState()
    const [localText, setLocalText] = useState("")

    useEffect(() => {
        if (localText) {
            setLocalText("")
        }
        setShowShow(null)
        // soundManager.playURL(narration)
    }, [narration, text])
    const publish = useCallback(async () => {
        const result = await next(document.getElementById("itmVal").value)
        if (!result.isCorrect && !result.retry) {
            setShowShow(true)

        }
        if (result.retry) {
            setLocalText("")
            document.getElementById("itmVal").value = ""
        }

    }, []);
    window.typesPublish = publish;

    useEffect(() => {
        function handlerEnterEvents(e) {
            if (e.key == "Enter") {
                publish()
            }
        }
        window.addEventListener("keydown", handlerEnterEvents)
        return () => {
            window.removeEventListener("keydown", handlerEnterEvents)
        }
    }, [])


    return <form className={styles.inputItemContainer} action={"javascript:void(0);"} onSubmit={async (d) => {
        d.preventDefault()
    }}>
        {
            shouldShow ?
                <input key={"showable"} autoFocus={true} id={"itmshow"} value={text} className={styles.inputItem} style={{ color: "#2F80ED" }} readOnly={true} />
                : <input key={narration} autoFocus={true} id={"itmVal"} className={styles.inputItem} placeholder={"Type in..."} onChange={(e) => {
                    setLocalText(e.target.value)
                }} value={localText} />

        }

        <ButtonHolder
            reference={`typesPublish${localText}`}
            onClick={async () => {
                await publish();
            }

            } icon={"nextarrow"} title={"Continue"} background={"green"} type={"submit"} />
    </form>
}

export default Day4;
