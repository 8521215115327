import styles from "./ReadItOut.module.css"
import React, { useEffect, useState } from "react";
import { playSound } from "../../utils/playsound";
import wait from "../../utils/wait";

import clsx from "clsx";
import { formatTime, genColor, randomIntFromInterval } from "../../utils/common";
import { mockAudio, pauseAll, waitForVoice } from "../../utils/audios";
import BeatRead21 from "../../components/common/BeatRead21";
import FloatingObject from "../../components/common/FloatingObjects";
import { useInstruction } from "../../utils/instruction";
import BaseStage from "../interfaces/BaseStage";
import ItemTable from "../neutral/ItemTable";
import StageIntroduction from "../introduction/StageIntroduction";
import { Flipped, Flipper } from "react-flip-toolkit";
import RecordButton from "../neutral/RecordButton";
import Button, { SlottedButton } from "../common/Button";
import useCountDown from "../../utils/countdown";
import { continueLesson, endLessonContinuate } from "../../resources/api";



function PreviewSentence({ timeLimit, disabled, data, current, onEnded }) {
    const { reset, countDown, stop, state } = useCountDown(timeLimit, true)
    useEffect(() => {
        if (!disabled) {
            reset()
            return () => stop()
        }


    }, [disabled])
    useEffect(() => {
        if (state === "complete") {
            onEnded()
        }
    }, [state])
    return <div className={styles.sentenceExampleContainer}>
        <div className={styles.buttonAndTimer} style={{
            alignSelf: "stretch",
            alignItems: "flex-end",
            justifyContent: "flex-end",
            marginBottom: "15px"
        }}>
            {
                !disabled && <span className={styles.timer} style={{
                    color: "#fff",
                    backgroundColor: "#2664B8",
                    padding: "5px 10px",
                    borderRadius: "10px",

                }}>{formatTime(countDown)}</span>
            }
        </div>
        <p>
            {
                data[current].text.map(it => <>{it}<br /><br /></>)
            }
        </p>

        <SlottedButton isDisabled={state === "stale"} direction="left" onClick={async () => {
            stop()
            onEnded()
        }} icon={"nextarrow"} border={"black"} background={"green"} title={`Next`} />
    </div>
}


class ReadItOut extends BaseStage {
    state = {
        page: ""
    }

    render() {
        const { status, data, current, nextItem, controls: { registerMicrophone, sentenceResults: results, goToResults }, nextPage, currentPage, repeatId, trialId, countDown, stageDetails } = this.props
        const { subType } = stageDetails

        // console.log({ current })

        const isGroupChanging = ((nextPage != null) && (nextPage !== currentPage));

        const oneAfterAnother = (subType == "sequential")
        return <StageIntroduction beforeShown={async () => {
            await new Promise((resolve, reject) => {
                this.setState({ continue: resolve, page: "nextPage" })
            })
        }
        } isOptionalGrowth={this.props.controls.optionalGrowth} stageDetails={stageDetails}
            onShown={async () => {
                await wait(1000)
                var uiActive = false;
                if (stageDetails.steps) {
                    const { steps } = stageDetails;
                    for (let i = 0; i < steps.length; i++) {
                        const step = steps[i]
                        if (step.type == "display") {
                            this.setState({ page: "display", content: step.content })
                            await wait(600)
                        } else if (step.type == "audio") {
                            if (step.uiBefore) {
                                uiActive = true;
                                this.setState({ page: "lesson" })
                            }
                            await soundManager.playSound("instruction", step.content)
                            await wait(step.afterDelay)
                        } else if (step.type == "preview") {
                            if (step.disabled) {
                                this.setState({ page: "preview", timeOut: step.timeOut, disabled: step.disabled })
                            } else {
                                await (new Promise((resolve, reject) => {
                                    this.setState({ page: "preview", close: resolve, timeOut: step.timeOut, disabled: step.disabled })
                                }))
                            }
                        }
                    }
                }
                if (!uiActive) {
                    this.setState({ page: "lesson" })
                }
                this.props.controls.initiateQuestions()
            }}>
            <div className={clsx(styles.titleContainer)}>
                {stageDetails.title && <h1 className={styles.title}>{this.state.titleSet || stageDetails.title}</h1>}
                {
                    this.state.page == "nextPage" && <div className={styles.contentContainer}>
                        <h1 className={styles.nextPrompt}>What next? </h1>
                        <Button direction="left" onClick={async () => {
                            this.state.continue()
                            this.setState({ page: "" })
                        }} icon={"nextarrow"} border={"black"} background={"green"} title={`Next Level`} />
                        <Button direction="left" onClick={async () => {
                            await pauseAll()
                            const result = await endLessonContinuate(21, trialId)
                            goToResults();
                        }} icon={"finish"} border={"black"} background={"blue"} title={`Finish Lesson`} />
                    </div>
                }
                {
                    this.state.page == "preview" && <PreviewSentence disabled={this.state.disabled} timeLimit={this.state.timeOut} data={data} current={current} stageDetails={stageDetails} onEnded={() => {
                        this.state.close()
                    }} />
                }
                {
                    this.state.page == "lesson" && <div className={clsx(styles.rioContainer, stageDetails.subType == "large" && styles.large)}>
                        {
                            stageDetails.subType == "large" ? <>
                                <div className={styles.sentences}>
                                    {
                                        data[current].text.map((nLine, ti) => {
                                            return <div key={`line_${ti}`} className={clsx(styles.sequences, styles.active)}>{nLine.split(" ").map((word, ki) => {
                                                var previousTickers = Array.from(new Array(ti)).reduce((prev, curr, ci) => {
                                                    return prev + data[current].text[ci].split(" ").length
                                                }, 0)
                                                var i = previousTickers + ki;
                                                var wRes = results?.[current]
                                                var wrdK = word.trim().replace(/[^A-Za-z0-9_ -]+/, '').toLowerCase()
                                                var result = wRes?.[wrdK + i]//virtualrep[wrdK] ? wRes?.[virtualrep[wrdK]+i] : wRes?.[wrdK+i]
                                                return <span id={`${wrdK}_${i}`} key={`${wrdK}_${i}`} className={clsx(styles.word, (result == "read") && styles.readAnimate, (typeof result == "boolean") && (result ? styles.correct : styles.error))}  >{word}</span>
                                            })}</div>
                                        })
                                    }
                                </div>
                                {
                                    <RecordButton
                                        {...(registerMicrophone(data[current]?.microphone || stageDetails.microphone))}
                                    />
                                }
                            </> : <>

                                <div className={styles.sentences}>
                                    {
                                        Object.keys(data).filter(oKey => !oneAfterAnother || (current == oKey)).map((oKey) => {
                                            var wRes = results?.[oKey]
                                            // console.log({ wRes, oKey })
                                            return <div key={oKey} className={clsx(styles.sequences, (current == oKey) && styles.active)}> {data[oKey].text.split(" ").map((word, i) => {
                                                var wrdK = word.trim().replace(/[^A-Za-z0-9_ -]+/, '').toLowerCase()
                                                var result = wRes?.[wrdK + i]
                                                return <span id={`${wrdK}_${i}`} key={wrdK + i} className={clsx(styles.word, (result == "read") && styles.readAnimate, (typeof result == "boolean") && (result ? styles.correct : styles.error))}  >{word}</span>
                                            })} </div>
                                        })
                                    }
                                    {/* <h1></h1> */}
                                </div>
                                {
                                    <RecordButton
                                        {...(registerMicrophone(data[current]?.microphone || stageDetails.microphone))}
                                    />
                                }
                            </>
                        }

                    </div>
                }

                <BeatRead21 />
                <FloatingObject color={"rgba(78,84,200, 0.2)"} status={status} isHiding={isGroupChanging} />
            </div>
        </StageIntroduction>
    }
}

function withMyHook(Component) {
    return function WrappedComponent(props) {
        useInstruction(props.stageDetails?.instruction && [props.stageDetails?.instruction]);
        return <Component {...props} />;
    }
}


export default withMyHook(ReadItOut)
